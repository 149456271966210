<template>
  <p class="clearfix mb-0">
    <span class="float-md-left d-block d-md-inline-block mt-25">
      FotoTrace {{ new Date().getFullYear() }} ©
    </span>
    <span class="float-md-right d-block d-md-inline-block mt-25">
      Version 1.0
    </span>
  </p>
</template>

<script>
export default {};
</script>
