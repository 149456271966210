export default [
  {
    title: "Clientes",
    route: "clients",
    icon: "UserIcon",
  },
  {
    title: "Gestión",
    icon: "CheckSquareIcon",
    children: [
      {
        title: "Eventos",
        route: "events",
      },
      {
        title: "Formularios",
        route: "forms",
      },
      {
        title: "Cliente nuevo",
        route: "importClient",
      },
    ],
  },
  {
    title: "Actuaciones",
    route: "maintenance",
    icon: "CheckSquareIcon",
  },
  {
    title: "GestionProductos",
    icon: "ShoppingCartIcon",
    children: [
      {
        title: "Productos",
        route: "products",
      },
      {
        title: "Categorías",
        route: "categories",
      },
    ],
  },
];
