export default [
  {
    title: 'Inicio',
    route: 'dashboard',
    icon: 'HomeIcon',
  },
  {
    title: 'Clientes',
    route: 'clients',
    icon: 'UserIcon',
  },
  /* {
    title: 'EPI',
    route: 'assets',
    icon: 'ServerIcon',
  }, */
  /* {
    title: 'Gestión',
    icon: 'CheckSquareIcon',
    children: [
      {
        title: 'Tareas',
        route: 'tasks',
      },
      {
        title: 'Eventos',
        route: 'events',
      },
      {
        title: 'Formularios',
        route: 'forms',
      },
      {
        title: 'Proyectos',
        route: 'projects',
      },
    ],
  }, */
  {
    title: 'Etiquetas',
    icon: 'TagIcon',
    children: [
      {
        title: 'Etiquetas',
        route: 'tags',
      },
      {
        title: 'Series',
        route: 'series',
      },
    ],
  },
  {
    title: 'GestionProductos',
    icon: 'ShoppingCartIcon',
    children: [
      {
        title: 'Productos',
        route: 'products',
      },
      {
        title: 'Categorías',
        route: 'categories',
      },
    ],
  },
  {
    title: 'Empresa',
    icon: 'BriefcaseIcon',
    children: [
      // {
      //   title: 'Localizaciones',
      //   route: 'locations',
      // },
      {
        title: 'Usuarios',
        route: 'users',
      },
      {
        title: 'Departamentos',
        route: 'departments',
      },
      {
        title: 'Notificaciones',
        route: 'notifications',
      },
    ],
  },
]
