export default [
  {
    title: "Inicio",
    route: "dashboard",
    icon: "HomeIcon",
  },
  {
    title: "Activos",
    route: "assets",
    icon: "ServerIcon",
  },
  {
    title: "Tareas",
    route: "tasks",
    icon: "FileTextIcon",
  },
  {
    title: "Etiquetas",
    icon: "TagIcon",
    children: [
      {
        title: "Etiquetas",
        route: "tags",
      },
      {
        title: "Series",
        route: "series",
      },
    ],
  },
  {
    title: "Actuaciones",
    route: "maintenance",
    icon: "CheckSquareIcon",
  },
  {
    title: "Gestión",
    icon: "CheckSquareIcon",
    children: [
      {
        title: "Eventos",
        route: "events",
      },
      {
        title: "Formularios",
        route: "forms",
      },
      {
        title: "Proyectos",
        route: "projects",
      },
    ],
  },

  {
    title: "GestionProductos",
    icon: "ShoppingCartIcon",
    children: [
      {
        title: "Productos",
        route: "products",
      },
      {
        title: "Categorías",
        route: "categories",
      },
    ],
  },

  {
    title: "Empresa",
    icon: "BriefcaseIcon",
    children: [
      {
        title: "Usuarios",
        route: "users",
      },
      {
        title: "Clientes",
        route: "subclients",
      }
    ],
  },
];
