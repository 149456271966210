export default [
  {
    title: "Inicio",
    route: "dashboard",
    icon: "HomeIcon",
  },
  {
    title: "Activos",
    route: "assets",
    icon: "LayersIcon",
  },
  {
    title: "Tareas",
    route: "tasks",
    icon: "ClipboardIcon",
  },
  {
    title: "Actions",
    route: "maintenance",
    icon: "CheckSquareIcon",
  },
  {
    title: "Notificaciones",
    route: "notifications",
    icon: "BellIcon",
  },
  {
    title: "Etiquetas",
    icon: "TagIcon",
    children: [
      {
        title: "Etiquetas",
        route: "tags",
      },
      {
        title: "Series",
        route: "series",
      },
    ],
  },
  {
    title: "Gestión",
    icon: "SettingsIcon",
    children: [
      {
        title: "Datos empresa",
        route: "viewClient",
      },
      // {
      //   title: "Localizaciones",
      //   route: "locations",
      // },
      {
        title: "Formularios",
        route: "forms",
      },
      {
        title: "Usuarios",
        route: "users",
      },
      {
        title: "Departamentos",
        route: "departments",
      },
      {
        title: "Clientes",
        route: "subclients",
      },
      {
        title: "Productos",
        route: "products",
      },
    ],
  },
];
